.dropzone {
    display: block;
    background-color: rgba(0, 0, 0, 0.1);
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    padding: 2em;
    border: 0.5em dashed rgba(0, 0, 0, 0.2);
}

.uploaderTab {
    padding: 1em;
}

.uploadText {
    width: 100%;
    height: 10em;
}